import React from 'react';
import { useCollapse } from 'react-collapsed';

function Alliances({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
   
    <div>
      <div className='activity-sec'>
				<h2  className='activity-title'>
                Alliances
				</h2>


                <div className='activity-content'>

                <p className='mission-text'> Determine the alliances that were created, maintained and/or persisted as a result of the conflict.  </p>
                

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
      <h2>{title}</h2>

      <div {...getCollapseProps()}>{isExpanded && children}
                   
                
               

                <h3 className='outline-head'>Objective</h3>
                <p className='outline-text'> As there became a need for international involvement during the crisis, nations began to align with conflicting nation states. </p>

                <p className='outline-big6'>
                      The following inquiry questions focus on specific  <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>Historical Thinking Concepts</a>: <br />
                      Continuity and Change, Cause and Consequence, Historical Perspectives, Ethical Dimension, Primary Source Documents and Historical Significance.
                </p>

                <h3 className='outline-head'>Action Steps </h3>
                <p className='outline-text'> Create a chart of all the major nation states (countries) that were involved in this conflict using the following headings:<br />
                  Nation/State<br />
                  Conflict Alliance (list other countries that belonged to the same alliance during the conflict)<br />
                  Great War alliance<br />
                  Second World War alliance<br />
                  Current Alliances (you can use the following link to help: <a href='https://www.worlddata.info/alliances/index.php' target='_blank'>World Data.info</a>)<br />
                  Region of the World <br />
                  Religious affiliation<br />

                </p>

                <h3 className='outline-head'>Follow-Up Questions</h3>
                <p className='outline-text'> Do you notice a similar alliance that resulted from the Great War (WWI)? Second World War (WWII)? <br />
                Does the friction that managed to result in conflict, still exist today?<br />
                </p>


                <h3 className='outline-head'>Debrief & Next Steps</h3>
                <p className='outline-text'> </p>
                </div>
                </div>
			</div>
    </div>

  );
}

export default Alliances;