export const MenuItems = [
    {
        title: 'Mission Control',
        url: '/mission-control',
        cName: 'nav-links'
    },
    {
        title: 'Timeline',
        url: '/timeline',
        cName: 'nav-links-timeline'
    },
    {
        title: "Activities",
        url: "/student-teacher-activities",
        cName: 'nav-links-activities' 
    }


]