import {useRef, useEffect, useState} from 'react'
import '../Navigation/Sidebar.css'
import "./OneDayOption.css"
import TopNav from '../Navigation/TopNav';
import { slide as Menu } from 'react-burger-menu'
import { Link, useLocation } from 'react-router-dom';
import IntroductionVideo from './IntroductionVideo1'
import MissionControlActivity from './MissionControlActivity';
import TimelineActivity from './TimelineActivity1'

function OneDayOption() {
    
    // These variables are for the props to be passed between pages
    const location = useLocation();
    console.log ("Timeline useLocation: " + JSON.stringify(location));
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(state.language);

    const introvideo = useRef(null);
    const missioncontrol = useRef(null);
    const timeline = useRef(null);
    const veterantest = useRef(null);
	const photoactivity = useRef(null);

    useEffect(()=>{
        setMissionID(state.mission_id);
        setLanguage(state.language);
        console.log("One Day Option - Mission ID: " + missionID + " - Language: " + language);
       
    }, [])


    const scrollToSection = (elementRef) => {
       
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth'
            
        });
    };

   
    
        return(
            <div>
                <TopNav/>
        <div className=''>
     <div className=''>

        <Menu>
     <nav className='stop-nav-items'> 

     <Link to="/" style={{ textDecoration: 'none' }}>
                           
						   <i class=" fa-solid fa-house"></i> 
				 
								 
							 </Link>


             <div>
            <p className="comp-name">  ONE-DAY OPTION</p>
            <hr className='hrline' />
            </div>
             
             
            <ul>
                <li onClick = {() => scrollToSection(introvideo) }
                    className="cr-side-link"> 
                    <i class="fa-solid fa-gear"></i> 
                    Introduction Video
                 </li>
                <li  onClick = {() => scrollToSection(missioncontrol)} 
                     className="cr-side-link"> 
                     <i class="fa-solid fa-gear"></i> 
                    MIssion Control
                </li>
                <li  onClick = {() => scrollToSection(timeline)}
                     className="cr-side-link">
                         <i class="fa-solid fa-gear"></i> 
                    Timeline
                </li>
                {/* <li  onClick = {() => scrollToSection(veterantest)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                    Veteran Testimonial
                  </li>
				  <li  onClick = {() => scrollToSection(photoactivity)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                    Photo Interrogation
                  </li> */}
            </ul>

             
            </nav>  
            </Menu>
                </div>

            <div className='activity-back'> 

           
            <div className='option-summary'>
            <h2 className='sum-para-head'> One-Day Option </h2>
            <p className='sum-para'>The purpose of this page is to outline several exercises that 
                you can work through to gain a cursory knowledge of a Canadian
                 mission. The activities are expected to take approximately one hour to complete.</p>
            
            <div className='rec-card'>

            <p>
            The following are recommendations for the types of activities that can be completed within the alotted amount of time.
            The order, and length of time spent on each individual activity is entirely up to the teacher 
             to implement. All tasks have been carefully designed to follow the Big-Six Historical
             Thinking Concepts, which will allow you to engage in more meaningful work.
            </p>
            </div>
            
            </div>    
            
            <div ref ={introvideo}>
            
			<IntroductionVideo />
			
            </div>



            <div ref = {missioncontrol}>
            
            <MissionControlActivity />
			
            </div>



            <div ref = {timeline}>
            
			<TimelineActivity />
            
            </div>
            
			
    </div>
    </div>	
    </div>
            
        )
    }



export default OneDayOption
