import {useState} from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation  } from 'react-router-dom';

function TimelineActivity3({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  // These variables are for the props to be passed between pages
  const location = useLocation();
  console.log ("Timeline3 useLocation: " + JSON.stringify(location));
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);

  return (
    <div>


      <div className='activity-sec' >
				<h2  className='activity-title'>
                Timeline
				</h2>
                <div className='activity-content'>
                    
                
                <p className='mission-text'> Identify key events and locations prior, during and after the conflict.</p>

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
      <h2>{title}</h2>

      <div {...getCollapseProps()}>{isExpanded && children}
                

                <h3 className='outline-head'>Objective</h3>
                <p className='outline-text'> Your objective is to build on the information you’ve already learned to see 
                some of the key events and places that shaped the mission. </p>
                <p className='outline-big6'> 
                    Keep in mind the following <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>Historical Thinking Concepts</a> when watching the video: <br />
                    Continuity and Change, Cause and Consequence, Historical Perspectives, Ethical Dimension, Primary Source video images and Historical Significance.
                </p>

                <h3 className='outline-head'>Action Steps </h3>
                <p className='outline-text'> 
                    Navigate to the <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:2}}> Timeline</Link> and filter the events to 'Advanced Investigation'.<br />
                    Scroll through the timeline and learn how the events were interconnected and how each time shaped the entire mission.
                </p>

                <h3 className='outline-head'>Follow-Up Questions</h3>
                <p className='outline-text'> 
                    How do the dates and events relate to one another?<br />
                    What events on the timeline do you think are the most important? <br />
                    Are you able to see the role Canada played as a peacekeeping nation?<br />
                    How does this activity reflect how we interpret/understand events in history?<br />
                    How does geography influence the decisions that were made?<br />
                    How did colonialism play a role prior, during and after the conflict?<br />

                </p>


                <h3 className='outline-head'>Debrief & Next Steps</h3>
                <p className='outline-text'> 
                    Refer back to the list your class made for the question: “What was the important part of the video?” after watching the <Link to="/mission-control" state={{mission_id:missionID, language:language}}>Introduction Video</Link>. <br /> 
	                As a class, analyze and create your own list of answers to the question now that you have completed the Timeline.<br />
                    What’s the significance of whether the list is the same or different? (when combining the Intro Video list with the Timeline analysis)<br />

                    Look through the following areas on the portal to help build a greater depth of knowledge connected to the Timeline:<br />
                    <ul>
                        <li> <Link to="/mission-control" state={{mission_id:missionID, language:language}}> Introduction Video</Link></li>
                        <li> <Link to="/mission-control" state={{mission_id:missionID, language:language}}>  Mission Control</Link></li>
                    </ul>

                </p>
                
                </div>
                </div>

			</div>
    </div>
  );
}

export default TimelineActivity3;