import React, { useEffect, useState } from 'react';
import './VeteranInterviews.css';
import './TeacherResources.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { Link, NavLink } from 'react-router-dom';
// import { Document, Page } from 'react-pdf';
    
function VeteranInterviews(){
    const [interviewData,setData] = useState([]);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };

    const getData=()=>{
      fetch('../assets/PK75/PK75.json'
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      )
      .then(function(response){
        //console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        //console.log(myJson);
        setData(myJson)

      });
    }

    useEffect(()=>{
      getData()
    },[])

    
    
    return(
        <div>
            <LandingNav/>
            

            {/* Veteran Interviews Header Section */}
            <div className='veteran-header'>
                    <img
                    loading="lazy"
                    src='/veteran-header-image.jpg'
                    className='teacher-header-image'
                    />
            <div className='teacher-header-content'>

            <h2 className='teacher-header-heading'> 
                Preserving Legacies
            </h2>

            <p className='teacher-header-text'>Discover the extraordinary tales of valor as veterans recount 
            their service and sacrifices.</p>

            </div>

            </div>




            {/* Veteran Interviews List Section */}

            <div className='veteran-list-container'>

                <div className='veteran-list-header'>
                    <h2 className='veteran-list-title'> Veteran Interviews</h2>
                    <p className='veteran-list-subtitle'>Explore the unfiltered and authentic stories of veterans from various branches, 
                        shedding light on their unique journeys through service.</p>
                </div>


                
                <div className='veteran-list'>

                    { interviewData.map((interview_info) => {
                      return[
                        
                        <div className='veteran-card'>

                            <h2 className='vi-card-name'>{interview_info.Surname}, {interview_info.Given_Name}</h2>
                            <p className='vi-card-dash'> _ </p>  
                            <p className='vi-card-title'>{interview_info.Rank}</p>
                            <p className='vi-card-location'>Mission: {interview_info.Mission} ({interview_info.Year})</p>

                            <a className='veteran-card-button' href={"../assets/PK75/"+interview_info.First_PDF} target='_blank' rel="noreferrer">View Interview</a>
                            {/* <NavLink to={"#"}>
                              <button className="veteran-card-button"> 
                              <Link to={"../assets/PK75/"+interview_info.First_PDF} target="_blank" rel="noopener noreferrer" />
                                 <a href={"../assets/PK75/"+interview_info.First_PDF} target='_blank' rel="noreferrer">View Interview (Page 1)</a>
                              </button>
                            </NavLink>
                            <Document file={"../assets/PK75/"+interview_info.First_PDF} onLoadSuccess={onDocumentLoadSuccess} > 
                              <Page pageNumber={pageNumber} />
                            </Document> */}
                        </div>
                      ];
                    })}


                    
                    

                </div>

            </div>





            <Footer/>
        </div>
    )

}

export default VeteranInterviews;
  