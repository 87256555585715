import React, { useEffect, useState } from 'react';
import './VideoSection.css'
import { useLocation } from 'react-router-dom';

function VideoSection(){
  // These variables are for the props to be passed between pages
  const location = useLocation();
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);

  useEffect(()=>{
      setMissionID(location.mission_id);
      setLanguage(location.language);
      console.log("Video Section Component - Mission ID: " + missionID + " - Language: " + language);
  }, [])

  return(
    <div  className='video-player'>
      <iframe width="85%" height="100%" src="https://www.youtube.com/embed/N4CDRJ7xzX4" 
      title="Canada Remembers the Suez Crisis" frameborder="0" allow="accelerometer; 
      autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>

      </iframe>
        
    </div>
  )
}

export default VideoSection;