import './PhotoActivity.css'
import ImageSlider from './ImageSlider';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PhotoActivity = () => {

  // These variables are for the props to be passed between pages
  const location = useLocation();
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);
  const [jsonResult, setJSONResult] = useState({});

  const defaultPhotos = [
    {url: '/assets/images/UNEF-IMAGES/mine-sweepers.jpg'},
    {url: '/assets/images/UNEF-IMAGES/Tel-el-Kebir.jpg'},
    {url: '/assets/images/UNEF-IMAGES/Port_Said_from_air.jpg'}
    // Add more photo URLs as needed
  ];

  const [photos, setPhotos] = useState(defaultPhotos);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts

    setMissionID(state.mission_id);
    setLanguage(state.language);
    console.log("Video Activity Component - Mission ID: " + missionID + " - Language: " + language);
    getData(missionID);
  }, []);

  const getData = (m_id) => {
    // The location of the photos (and all other information) is found inside the mission_control.js

    fetch('../../assets/'+m_id+'/mission_control.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(myJson => {
      // console.log("This is myJson from (deconstruct) photo activity");
      // console.log(myJson);
      setJSONResult(myJson);
      
      const mission_info_photos = myJson.interrogate_photos;
      if (mission_info_photos){
        
        // Loop through the mission
        var new_photos = [];
        mission_info_photos.map((photo) => {
        //mission_info_photos.forEach((photo) => {
          console.log(photo);
          new_photos.push(photo);
        });
        setPhotos(mission_info_photos);
        //console.log("Mission photos set: " + JSON.stringify(new_photos)); 
      }
      else{
        console.log("No photos found in PhotoActivity.js");
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div className = "photo-activity-container">

      <p className='sel-title'>Select a photo to investigate:</p>

      
      <div className='slider-container'>
        <ImageSlider photos={photos} />
      </div>
  
    </div>
  );
};


export default PhotoActivity;