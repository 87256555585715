import React from 'react';
import PropTypes from 'prop-types';
import "./Accordion.css"

class AccordionSection extends React.Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;

    return (
      <div className='accordion-sec'
        style={{
          background: isOpen ? '#F1F1F1' : '#fff',
         
        }}
      >
        <div onClick={onClick} className = "accordion-head"style={{ cursor: 'pointer' }}>
          {label}
          <div style={{ float: 'right' }}>
            {!isOpen && <span>
              <img
            className="plus-icon"
            src='plus-icon.png'
            alt="" />
    </span>}
            {isOpen && <span>
              
              <img
            className="plus-icon"
            src='minus-icon.png'
            alt="" />

              </span>}
          </div>
        </div>
        {isOpen && (
          <div className='accordion-content-sec'
          
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;