import {useState} from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation } from 'react-router-dom';

function IntroductionVideo5({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    // These variables are for the props to be passed between pages
    const location = useLocation();
    console.log ("Intro video activity5 useLocation: " + JSON.stringify(location));
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(state.language);

  return (
    <div>
      <div className='activity-sec'>
				<h2  className='activity-title'>
                Introduction Video
				</h2>

                
                <div className='activity-content'>
                
                   <p className='mission-text'> Watch a video that outlines the conflict and the events that led to the need for international involvement.  </p>
                    

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
      <h2>{title}</h2>
              

                <div {...getCollapseProps()}>{isExpanded && children}
                  
   
                    {/* <h3 className='outline-head'>Mission</h3>
                <p className='outline-text'> Watch a video that outlines the conflict and the events that led 
                to the need for international involvement.   </p> */}
                

                <h3 className='outline-head'>Objective</h3>
                <p className='outline-text'> Your objective when watching a video is to gain a greater understanding of the events that led up to the conflict, 
                the key points during the mission and the aftermath.</p>

                <p className='outline-big6'>
                    Keep in mind the following <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>Historical Thinking Concepts</a> when watching the video: <br />
                    Cause and Consequence, Historical Perspectives, Ethical Dimension, Primary Source video footage and Historical Significance.
                </p>

                <h3 className='outline-head'>Action Steps </h3>
                <p className='outline-text'> Identify which video you were assigned to watch 
                    (there may be more than one) in the 
                    <Link to="/mission-control" state={{mission_id:missionID, language:language}}> Mission Control</Link>. <br />
                    Watch the video and think about the following questions.</p>

                <h3 className='outline-head'>Follow-Up Questions</h3>
                <p className='outline-text'> 
                    What was the most important part of the video? (You will use this answer in the Debrief section)<br />
                    Why do you believe the mission was important from a Regional perspective? National? International?<br />
                    Was there any mention of Canada’s involvement?<br />
                    How does this activity reflect how we interpret/understand events in history?<br />
                    Whose voice is missing from the video?<br />
                    Do you notice any bias in the production of the video?<br />
                </p>


                <h3 className='outline-head'>Debrief & Next Steps</h3>
                <p className='outline-text'> 
                    As a class, analyze and create a list of answers to the question: “What was the important part of the video?” <br />
                    Look through the following areas on the portal to help build your knowledge:<br />
                    <ul>
                        <li><Link to="/mission-control" state={{mission_id:missionID, language:language}}> Mission Control</Link></li>
                        <li> <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:3}}> Timeline</Link></li>
                    </ul>
                    
                </p>
                   </div>
                </div>
			</div>
      
      
    </div>
  );
}

export default IntroductionVideo5;