import React from 'react';
import { useCollapse } from 'react-collapsed';

function InquiryQuestion({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div>

      <div className='activity-sec'>
				<h2  className='activity-title'>
                Inquiry Question
				</h2>
        <div className='activity-content'>

          <p className='mission-text'> Work through a series of questions that focuses on a researchable idea whose answer takes the form of a claim that is supported by evidence, information, and reasoning.</p>


          <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
          <h2>{title}</h2>

          <div {...getCollapseProps()}>{isExpanded && children}
            



          <h3 className='outline-head'>Objective</h3>
          <p className='outline-text'> To gain a greater understanding of the events that led up to the conflict,
              the key points during the mission and the end result.</p>

          <p className='outline-big6'>
                The following inquiry questions focus on specific  <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>Historical Thinking Concepts</a>: <br />
                Continuity and Change, Cause and Consequence, Historical Perspectives, Ethical Dimension, Primary Source Documents and Historical Significance.
          </p>

          <h3 className='outline-head'>Action Steps </h3>
          <p className='outline-text'> Decide how many inquiry questions you will focus on and answer them to extend your knowledge.</p>

          <h3 className='outline-head'>Follow-Up Questions</h3>
          <p className='outline-text'> What role does Geography play when coordinating personnel, moving supplies, working with local communities, and serving in a peace-keeping role?<br /><br />
            In regards to the mission, what considerations need to be understood from a regional perspective before having troops set foot inside of the country? Are there cultural, religious or other factors that need to be understood?<br /><br />
            What groups would be in support/resistant to the mission taking place from a military perspective and having troops present?<br /><br />
            Imagine you were in the first rotation/contingent. What recommendations would you put in a statement or package to give to the next rotation. What lessons do you think the veterans learned? Focus on different key areas - political , economic, cultural, military - in your briefing.<br /><br />
            After reviewing the different events and Canada’s involvement, can we consider Canada as a peacekeeping nation?<br /><br />
            How is this mission important for Canada? Reflect on this mission and determine if you believe this mission was important for Canada to be involved?<br /><br />
          </p>


          <h3 className='outline-head'>Debrief & Next Steps</h3>
          <p className='outline-text'> 
          If students in your class chose another mission to investigate, compare the results of the answers to their "Inquiry Questions".<br /> 
          What differences do you notice in your answers? <br />
          What are the similarities that exist between the different conflicts and Canada’s response, as well as the global community?</p>
      </div>
</div>
			</div>
    </div>

    
  );
}

export default InquiryQuestion;