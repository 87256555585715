import React, { useRef, useEffect, useState } from 'react';
import './Map.css'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = 'pk.eyJ1IjoibGZjaG9ja2V5IiwiYSI6ImNqdHQ3OTJzYTAzbDM0NG9hcDJhcHM3YXgifQ.-xAMcIMztR--wvn0P57RnQ';


/*
Mapbox Integrated Maps
======================
Maps must have an access token to be able to connect to Mapbox (line 4)
All map information (countries/colours, longitude, latitude, pitch, zoom, etc.) is stored in a database. 
    The app must consume a RESTful API to collect the map database information. That is NOT implemented here. 
    The data is formatted in JSON (the structure of the JSON object is currently being revised)
    There is a Cross-Origin browser error when trying to run on localhost so we must have a local *.json file that mimics the API (or hard-code the data)
There are several 'layers' (countries-highlighted1, countries-highlighted2, ...) that are created when the map is initialized that can be re-used to colour specific countries.
    A single layer can have multiple countries, but must have the same colour.
The flyToLocation() function creates the animation after the countries have been coloured.
*/


export default function Map() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-30);
  const [lat, setLat] = useState(40);
  const [zoom, setZoom] = useState(1.5);
  const fly = () => {
    flyToLocation();
  }
  const colourCountry1 = () => {
    changeActiveCountryColour(['CA', 'CO', 'DK', 'US'], 'UNBlue');
    flyToLocation(0.0, 25, 2);
  }
  const colourCountry2 = () => {
    changeActiveCountryColour(['EG'], 'mainGold');
    flyToLocation(34.9482, 24.8677, 5);
  }
  const colourCountry3 = () => {
    changeActiveCountryColour(['GB'], 'positiveBlue');
    flyToLocation(-2.4783, 52.3, 4);
  }
  const colourCountry4 = () => {
    changeActiveCountryColour(['EE','GE','LT','LV','UA','MD','KG','UZ','TJ','AM','AZ','TM','BY','RU','KZ'], 'negativeRed');
    flyToLocation(73.6243, 57.4883, 2.8);
  }
  const colourCountry5 = () => {
    changeActiveCountryColour(['CZ', 'SK'], 'otherGreen');
    flyToLocation(-2.4783, 52.3, 4);
  }
  const clearLayers = () => {
    clearMapCountryColours();
  }

  // Initialize the map
  //      Also, add all of the layers we will use 
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/satellite-streets-v12', //mapbox://styles/mapbox/satellite-v9',
      center: [lng, lat],
      zoom: zoom,
      projection: 'mercator'
    });

    // When the map loads, add the sources and layers to use for polygons, country colours, arrows, etc.
    map.current.on('load', () => {
      
      const layers = map.current.getStyle().layers;
      // Find the index of the first symbol layer in the map style.
      //    The highlighted-countries layer will be placed below this one in the map layer stack
      let firstSymbolId;
      for (const layer of layers) {
        //console.log(layer.type + " - " + layer.id);
        if (layer.type === 'symbol') {
          firstSymbolId = layer.id;
          break;
        }
      }
      
      // Add source for country polygons using the Mapbox Countries tileset
      // The polygons contain an ISO 3166 alpha-3 code which can be used to for joining the data
      // https://docs.mapbox.com/vector-tiles/reference/mapbox-countries-v1
      map.current.addSource('countries', {
          type: 'vector',
          url: 'mapbox://mapbox.country-boundaries-v1'
      });

      // Create the layer for colouring in a selected country
      // Add filled county polygons for highlighted display.
      map.current.addLayer(
          {
              'id': 'countries-highlighted1',  //United Nations
              'type': 'fill',
              'source': 'countries',
              'source-layer': 'country_boundaries',
              'paint': {
                  'fill-outline-color': '#484896',
                  'fill-color': '#44bef1',
                  'fill-opacity': 0.9
              },
              // Display none by adding a filter with an empty string.
              'filter': ['in', 'iso_3166_1', '']
          },
          firstSymbolId
      );
      map.current.addLayer(
          {
              'id': 'countries-highlighted2', // Main Country
              'type': 'fill',
              'source': 'countries',
              'source-layer': 'country_boundaries',
              'paint': {
                  'fill-outline-color': '#484896',
                  'fill-color': '#b18f06',
                  'fill-opacity': 0.9
              },
              // Display none by adding a filter with an empty string.
              'filter': ['in', 'iso_3166_1', '']
          },
          firstSymbolId
      );
      map.current.addLayer(
          {
              'id': 'countries-highlighted3', // Positive Alliance
              'type': 'fill',
              'source': 'countries',
              'source-layer': 'country_boundaries',
              'paint': {
                  'fill-outline-color': '#484896',
                  'fill-color': '#0623b1',
                  'fill-opacity': 0.9
              },
              // Display none by adding a filter with an empty string.
              'filter': ['in', 'iso_3166_1', '']
          },
          firstSymbolId
      );
      map.current.addLayer(
          {
              'id': 'countries-highlighted4',     // Negative Alliance
              'type': 'fill',
              'source': 'countries',
              'source-layer': 'country_boundaries',
              'paint': {
                  'fill-outline-color': '#484896',
                  'fill-color': '#eb0f0f',
                  'fill-opacity': 0.9
              },
              // Display none by adding a filter with an empty string.
              'filter': ['in', 'iso_3166_1', '']
          },
          firstSymbolId
      );
      map.current.addLayer(
          {
              'id': 'countries-highlighted5',     // Additional Alliance
              'type': 'fill',
              'source': 'countries',
              'source-layer': 'country_boundaries',
              'paint': {
                  'fill-outline-color': '#484896',
                  'fill-color': '#1c8112',
                  'fill-opacity': 0.9
              },
              // Display none by adding a filter with an empty string.
              'filter': ['in', 'iso_3166_1', '']
          },
          firstSymbolId
      );


      // Create the Layers for polygons
      // Create the map source and layers for the polygons
      // Add a data source containing GeoJSON data.
      map.current.addSource('polygonSource', {
          'type': 'geojson',
          'data': {
              'type': 'Feature',
              'geometry': {
                  'type': 'Polygon',
                  'coordinates': [[]]
              }
          }
      });

      // Add a new layer to visualize the polygon.
      map.current.addLayer({
          'id': 'polygonLayer',
          'type': 'fill',
          'source': 'polygonSource', // reference the data source
          'layout': {},
          'paint': {
              'fill-color': '#0080ff', // blue color fill
              'fill-opacity': 0.5
          }
      });
    });    
  });


  // If the map moves, set the Lng, Lat, Zoom
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

  });



  // declare various easing functions. easing functions mathematically describe how fast a value changes during an animation.
  // each function takes a parameter t that represents the progress of the animation. t is in a range of 0 to 1 where 0 is the initial state and 1 is the completed state.
  var easingFunctions = {
      linear: function(t) {
              return t;
      },
      quad: function(t) {
              return t*t;
      },
      // start slow and gradually increase speed
      log: function (t) {
              return 1.1*Math.log2(t)+0.2;
      },
      // start slow and gradually increase speed
      easeInCubic: function (t) {
              return t * t * t;
      },
      // start fast with a long, slow wind-down
      easeOutQuint: function (t) {
              return 1 - Math.pow(1 - t, 5);
      },
      // slow start and finish with fast middle
      easeInOutCirc: function (t) {
              return t < 0.5
              ? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2
              : (Math.sqrt(1 - Math.pow(-2 * t + 2, 2)) + 1) / 2;
      },
      // fast start with a "bounce" at the end
      easeOutBounce: function (t) {
          var n1 = 7.5625;
          var d1 = 2.75;
          
          if (t < 1 / d1) {
              return n1 * t * t;
          } else if (t < 2 / d1) {
              return n1 * (t -= 1.5 / d1) * t + 0.75;
          } else if (t < 2.5 / d1) {
              return n1 * (t -= 2.25 / d1) * t + 0.9375;
          } else {
              return n1 * (t -= 2.625 / d1) * t + 0.984375;
          }
      }
  };

  function clearMapCountryColours(){
      map.current.setFilter('countries-highlighted1', [
          'in',
          'iso_3166_1',
          ''
      ]);
      map.current.setFilter('countries-highlighted2', [
          'in',
          'iso_3166_1',
          ''
      ]);
      map.current.setFilter('countries-highlighted3', [
          'in',
          'iso_3166_1',
          ''
      ]);
      map.current.setFilter('countries-highlighted4', [
          'in',
          'iso_3166_1',
          ''
      ]);
      map.current.setFilter('countries-highlighted5', [
          'in',
          'iso_3166_1',
          ''
      ]);
  }

  function zoomMap(lvl){
      map.current.setZoom(lvl);
  }

  function setBoundingBox(bbox){
      console.log(bbox);
      var sw = new mapboxgl.LngLat(bbox[0].replace('[',''), bbox[1]);
      var ne = new mapboxgl.LngLat(bbox[2], bbox[3].replace(']',''));
      var llb = new mapboxgl.LngLatBounds(sw, ne);
      map.current.fitBounds(llb, { padding: 5 });

  }

  function flyToLocation(lat = 0.0, lng = 0.0, zoom = 5){
    var animationOptions = {
      center: [lat, lng],
      duration: 1000,
      easing: easingFunctions["linear"],
      offset: [0, 0],
      bearing: 0,
      zoom: zoom,
      pitch: 0,
      animate: true,
      essential: true // animation will happen even if user has `prefers-reduced-motion` setting on
    };

    map.current.flyTo(animationOptions);
  }

  // Accepts an array of iso2 (countries_arr) and the colour as a string id 
  function changeActiveCountryColour(countries_arr, colour) {
      console.log("inside changeActiveCountryColour country: " + countries_arr);
  
      // Create the options for the filter
      var options = ['in', 'iso_3166_1']; 
  
      if (countries_arr.length > 0){
          // Push the names of the countries to be filtered onto the end of the array
          countries_arr.forEach(c => {
              options.push(c);
          });
      }
  
  
      // This filters the appropriate alliances based on the colour found
      if (colour== "UNBlue"){
          map.current.setFilter('countries-highlighted1', options);
      }
      else if (colour== "mainGold"){
          map.current.setFilter('countries-highlighted2', options);
      }
      else if (colour== "positiveBlue") {
          map.current.setFilter('countries-highlighted3', options);
      }
      else if (colour== "negativeRed"){
          map.current.setFilter('countries-highlighted4', options);
      }
      else if (colour== "otherGreen"){
          map.current.setFilter('countries-highlighted5', options);
      }
      else
      {
          map.current.setFilter('countries-highlighted1', options); 
      }
  }

  

  return (
    <div>
      {/*
     // The following was added to test out the changing of country colours inside Mapbox's layers.

      <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        <br/>
        <button onClick={fly}>Fly to New Location</button>
        <br/>
        <br />
        <button onClick={colourCountry1}>Colour UN</button>
        <br/>
        <button onClick={colourCountry2}>Colour Egypt</button>
        <br/>
        <button onClick={colourCountry3}>Colour UK</button>
        <br/>
        <button onClick={colourCountry4}>Colour USSR</button>
        <br/>
        <button onClick={colourCountry5}>Colour Czechoslovakia</button>
        <br/>
        <button onClick={clearLayers}>Clear Country Colours</button>
      </div> 
      */}
      <div ref={mapContainer} className="map-container" />
      
    </div>
  );
  
}