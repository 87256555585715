import React from 'react';
import { useCollapse } from 'react-collapsed';

function CommemorationEvaluation({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (

    <div>
    <div className='activity-sec'>
      <h2  className='activity-title'>
              Commemoration Evaluation
      </h2>


              <div className='activity-content'>

              <p className='mission-text'> Design a commemoration event or exhibit to honour the Canadian veterans who served during the mission.</p>
              

              <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
    <h2>{title}</h2>

    <div {...getCollapseProps()}>{isExpanded && children}
                 
              
             

              <h3 className='outline-head'>Objective</h3>
              <p className='outline-text'> Using the information you have learned throughout the other activities, create or map out a remembrance activity 
              that will embrace the service of Canadian veterans and that  recognize their presence.</p>

              <p className='outline-big6'>
                Keep in mind the following <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>Historical Thinking Concepts</a> when creating a remembrance activity:<br />
                Continuity and Change, Cause and Consequence, Historical Perspectives, Ethical Dimension, Primary Source Evidence and Historical Significance.
              </p>  

              <h3 className='outline-head'>Action Steps </h3>
              <p className='outline-text'> Decide whether you will complete this activity individually or as part of a group.<br />
                Brainstorm different ways that you can commemorate those that served (i.e., a formal service, postage stamp, memorial, a coin or bill, etc.).<br />
                What images and symbols do you believe would be essential for Canadians to remember the mission and for veterans to be able to see themselves in?<br />
                Create a blueprint that illustrates in detail what the event or exhibit looks like. <br />
              </p>

              <h3 className='outline-head'>Follow-Up Questions</h3>
              <p className='outline-text'> How would you define commemoration? Is commemoration more than ‘remembering’?<br />
                Why are commemorations created?<br />
                Is it important to remember the past and honor the actions of both heroes and those who lost their lives? Why?<br />
                What is the benefit of honoring the actions of people in the past or even heroes in the present? <br />
                What is the purpose of your design?<br />
                Who is the intended audience?<br />
                Is it possible to make sure all voices are heard based on your design?<br />
                What extra permissions or approvals would need to happen in order to bring your ideas to life?<br />
              </p>


              <h3 className='outline-head'>Next Steps</h3>
              <p className='outline-text'> Are you able to take the blueprint you designed and create a real-life commemoration activity?<br />
                Share your commemoration activity with your class.
              </p>
              </div>
              </div>
    </div>
  </div>


  );
}

export default CommemorationEvaluation;