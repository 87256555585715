import {useRef} from 'react'
import '../Navigation/Sidebar.css'
import "./Canada'sRole.css"
import TopNav from '../Navigation/TopNav';




function CanadaRole() {
    
    

    const fallen = useRef(null);
    const veteraninterviews = useRef(null);
    const perspective = useRef(null);
    const missioncontingent = useRef(null);
	const diplomaticrole = useRef(null);
    const unitrotation = useRef(null);

    const scrollToSection = (elementRef) => {
       
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth'
            
        });
        

    };
   
    
        return(
            <div>
                <TopNav/>
        <div className='flexbox-container'>
     <div className='flexbox-item flexbox-item-1'>
     <nav className='stop-nav-items'> 
            <div className="smenu-icon">
                <i class="fa-solid fa-bars"></i>
             </div>
             <div>
            <p className="comp-name">  CANADA'S ROLE</p>
            <hr className='hrline' />
            </div>
             
             
            <ul>
                <li onClick = {() => scrollToSection(fallen) }
                    className="cr-side-link"> 
                    <i class="fa-solid fa-gear"></i> 
                    The Fallen
                 </li>
                <li  onClick = {() => scrollToSection(veteraninterviews)} 
                     className="cr-side-link"> 
                     <i class="fa-solid fa-gear"></i> 
                      Veteran Interviews
                </li>
                <li  onClick = {() => scrollToSection(perspective)}
                     className="cr-side-link">
                         <i class="fa-solid fa-gear"></i> 
                          Perspective
                </li>
                <li  onClick = {() => scrollToSection(missioncontingent)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                  Mission Contingent
                  </li>
				  <li  onClick = {() => scrollToSection(diplomaticrole)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                  Diplomatic Role
                  </li>
				  <li  onClick = {() => scrollToSection(unitrotation)}
                         className="cr-side-link"> 
                        <i class="fa-solid fa-gear"></i> 
                  Unit Rotation
                  </li>
            </ul>

             
             
                
            
             
            </nav>
                </div>

            <div className='flexbox-item flexbox-item-2'>
            
            <div className='content-section' ref ={fallen}>
            
			<div className='c-role1'>
				<h2>
					The Fallen
				</h2>

			</div>
			
            </div>
            <div ref = {veteraninterviews}>
            <div className='c-role2'>
				<h2>
					Veteran Interviews
				</h2>

			</div>
			
			
            </div>
            <div ref = {perspective}>
            
			<div className='c-role3'>
				<h2>
					Perspective
				</h2>

			</div>
			
            </div>
            <div ref ={missioncontingent}>
            
			<div className='c-role4'>
				<h2>
					Mission Contingent
				</h2>

			</div>
			
            </div>
			<div ref ={diplomaticrole}>
            
			<div className='c-role5'>
				<h2>
					Diplomatic Role
				</h2>

			</div>
			
            </div>
			<div ref ={unitrotation}>
            <div className='c-role6'>
				<h2>
					Unit Rotation
				</h2>

			</div>
			
			
            </div>
           
    </div>
    </div>	
    </div>
            
        )
    }



export default CanadaRole
