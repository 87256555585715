import {useState} from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation } from 'react-router-dom';

function CanadasRole({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  // These variables are for the props to be passed between pages
  const location = useLocation();
  console.log ("Canada's Role useLocation: " + JSON.stringify(location));
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);

  return (

    
    <div>
      <div className='activity-sec'>
				<h2  className='activity-title'>
                Canada's Role
				</h2>


                <div className='activity-content'>

                <p className='mission-text'> Identify Canada’s role during the conflict, the locations of the most pivotal events and learn more about our nation’s involvement.   </p>
                

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
      <h2>{title}</h2>

      <div {...getCollapseProps()}>{isExpanded && children}
                   
                
               

                <h3 className='outline-head'>Objective</h3>
                <p className='outline-text'> Students will work through many of the events and connections Canada had during its involvement in the mission. By understanding the role Canada played in the mission you may begin to see the sacrifice of the veterans who served. </p>

                <p className='outline-big6'>
                    Keep in mind the following <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>Historical Thinking Concepts</a> when watching the video: <br />
                    Continuity and Change, Cause and Consequence, Historical Perspectives, Ethical Dimension, Primary Source images and Historical Significance.
                </p>

                <h3 className='outline-head'>Action Steps </h3>
                <p className='outline-text'> Navigate to the <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:4}}> Timeline</Link> 
                and filter the events to ‘Canada’s Role’.<br />
                Scroll through the timeline and learn how the events were interconnected.
                </p>

                <h3 className='outline-head'>Follow-Up Questions</h3>
                <p className='outline-text'> Do you believe Canada should be considered a peacekeeping nation?<br />
                  What do you believe might be the most dangerous aspects of daily life for veterans who served?<br />
                  Does the location of where Canadians were stationed during the conflict seem to make sense? If you were to choose a location for the service member’s base of operations, where would you locate it?<br />
                </p>


                <h3 className='outline-head'>Debrief & Next Steps</h3>
                <p className='outline-text'> Discuss with your classmates what it means to be a veteran who served in Canada’s military during this mission by investigating the ‘Follow-Up Questions’ together as a class.<br />
                </p>
                </div>
                </div>
			</div>
    </div>
  );
}

export default CanadasRole;