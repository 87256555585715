import React, { useState } from 'react';
import { MenuItems } from './MenuItemTop';
import './TopNav.css';
import { Link, NavLink, useLocation } from 'react-router-dom';

const TopNav = () => {
    // These variables are for the props to be passed between pages
    const location = useLocation();
    const { state } = location;
    //console.log("TopNav state: " + JSON.stringify(state));
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(state.language);

  return (
    <>
      <div className='nav-div'>
        <nav className='top-nav-items'>
          <div className='logo-section'>
            <Link to='/'>
              <img className='gregg-logo-tn' src='/gregg_logo.png' alt='Logo' />
            </Link>
            <Link to='/' style={{ textDecoration: 'none' }}>
              <h1 className='land-topnav-logo'>In Service of Canada</h1>
            </Link>
          </div>
          <div className='menu-icon'></div>
          <ul className='topnav-menu'>
            {MenuItems.map((item, index) => (
              <li key={index}>
                <div className='navlink-container'>
                  <NavLink className={item.cName}  to={item.url} state={state}>
                    {item.title}
                  </NavLink>
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default TopNav;
