import {useState} from 'react';
import { useCollapse } from 'react-collapsed';
import { Link, useLocation } from 'react-router-dom';

function MissionControlActivity({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  // These variables are for the props to be passed between pages
  const location = useLocation();
  console.log ("Mission Control Activity useLocation: " + JSON.stringify(location));
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);

  console.log("MC Activity missionID: " + missionID);

  return (

    
    <div>
      <div className='activity-sec'>
				<h2  className='activity-title'>
               Mission Control
				</h2>


                <div className='activity-content'>

                <p className='mission-text'>Identify how three main scopes of the conflict - Backstory, Cause, and Setting - were intertwined.  </p>
                

                <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
      <h2>{title}</h2>

      <div {...getCollapseProps()}>{isExpanded && children}
                   
                
               
      <h3 className='outline-head'>Objective</h3>
                <p className='outline-text'> Your objective is to learn key information about the conflict that will help you navigate through other essential components of the mission.</p>
                <p className='outline-big6'>
                    Keep in mind the following <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>Historical Thinking Concepts</a> while reading the text and viewing the map: <br />
                    Continuity and Change, Cause and Consequence, Historical Perspectives, Historical Significance.
                </p>

                <h3 className='outline-head'>Action Steps </h3>
                <p className='outline-text'> Navigate to the <Link to="/mission-control" state={{mission_id:missionID, language:language}}> Mission Control</Link>. <br />
                    Read through the Backstory, Cause and Setting. <br />
                    Think about how the map helps to tell its own story while scrolling through the text.<br />
                </p>

                <h3 className='outline-head'>Follow-Up Questions</h3>
                <p className='outline-text'> Explain how the map helps to clarify the connections made between each of the following scopes: Backstory, Cause and Setting.<br />
                    Do you notice any new revelations after reading through the Mission Control that you did not see during the introduction video? <br />
                    At this point in time, do you feel you have enough evidence that Canada needed to engage in this military operation?<br />
                    Choose one component of the Big-Six Concepts (Continuity and Change, Cause and Consequence, Historical Perspectives, Historical Significance,<br />
                    Ethical Dimension or Primary Source Evidence) that helped you understand why the mission was important enough for Canada to be involved. <br />

                </p>


                <h3 className='outline-head'>Debrief & Next Steps</h3>
                <p className='outline-text'> 
                    After watching the Introduction Video and reading through the Mission Control, reflect on what you feel are the most important aspects of this mission.<br />
                    Look through the following areas on the portal to help build a greater depth of knowledge connected to the Mission Control:<br />
                    <ul>
                        <li> <Link to="/mission-control" state={{mission_id:missionID, language:language}}> Introduction Video</Link></li>
                        <li> <Link to="/timeline" state={{mission_id:missionID, language:language, timeline_filter:1}}> Timeline</Link></li>
                    </ul>
                </p>
                </div>
                </div>
			</div>
    </div>
  );
}

export default MissionControlActivity;