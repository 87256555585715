import React, { useState } from "react";
import "./VideoSlider.css" 

const slideStyles = {
    
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const rightArrowStyles = {
  
};

const leftArrowStyles = {
  position: "absolute",
  top: "55%",
  transform: "translate(0, -50%)",
  left: "10px",
  fontSize: "30px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  borderRadius: "10px",
  padding: "2vh"
};

const sliderStyles = {
  position: "relative",
  height: "100%",
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center",
};


const VideoSlider = ({ videos }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? videos.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    const stopVideo = document.querySelectorAll('.video-slide');
    stopVideo.forEach((iframeVideo) => {

      iframeVideo.contentWindow.postMessage(
        '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
        '*'
      );
    });
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === videos.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    const stopVideo = document.querySelectorAll('.video-slide');
    stopVideo.forEach((iframeVideo) => {

      iframeVideo.contentWindow.postMessage(
        '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
        '*'
      );
    });
    

  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div>
    <div className="vid-slider" style={sliderStyles}>
      <div className="to-previous" onClick={goToPrevious} >
        ❰
      </div>
      <div className="to-next" onClick={goToNext} >
        ❱
      </div>
      <div>
      {videos.map((video, index,) => (

        <h3 className="vid-head" style={{
        
        
        color: "#36493c" ,
       
        display: index === currentIndex ? "block" : "none",
        }}>
          {video.txt}
        </h3>

      ))}

      </div>
      <div className="video-player">
        {videos.map((video, index) => (

          
          
          <iframe
          
            
            width="85%" 
            height="100%" 
            title="Canada Remembers the Suez Crisis" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            className="video-slide"
            src={video.url}
            key={index}
            alt={`Slide ${index}`}
            style={{
              ...slideStyles,
              display: index === currentIndex ? "block" : "none",
            }}
          > 
          
          </iframe>
        ))}
      </div>
      <div style={dotsContainerStyles}>
        {videos.map((video, index) => (
          <div
          className="dotStyle"
            key={index}
            onClick={() => goToSlide(index)}
          >
            ●
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default VideoSlider;
