import React from 'react';
import { useCollapse } from 'react-collapsed';

function MajorPlayers({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    
    <div>
    <div className='activity-sec'>
      <h2  className='activity-title'>
             Major Players
      </h2>


              <div className='activity-content'>

              <p className='mission-text'> Watch a video that outlines the conflict and why international involvement was needed.   </p>
              

              <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
    <h2>{title}</h2>

    <div {...getCollapseProps()}>{isExpanded && children}
                 
              
             

              <h3 className='outline-head'>Objective</h3>
              <p className='outline-text'> To gain a greater understanding of the events that led up to the conflict,
                   the key points during the mission and the end result.</p>

              <h3 className='outline-head'>Action Steps </h3>
              <p className='outline-text'> Identify which video you were assigned to watch 
                  (there may be more than one) in the Mission Control. 
                  Watch the video and think about the following questions.</p>

              <h3 className='outline-head'>Follow-Up Questions</h3>
              <p className='outline-text'> Do you notice any bias in the production of the video?
                  Whose voice is missing?
                  Why do you believe the mission was important from a Regional perspective? National? International?
                  Was there any mention of Canada’s involvement? </p>


              <h3 className='outline-head'>Next Steps</h3>
              <p className='outline-text'> Look through the following areas on the portal to help build your knowledge:
                  Mission Control
                  Timeline</p>
              </div>
              </div>
    </div>
  </div>


  );
}

export default MajorPlayers;