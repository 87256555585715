import React from 'react';
import './Contact.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { NavLink } from 'react-router-dom';
    
function Contact(){


    return(

        <div>

            <LandingNav/>

             {/* Contact Us Section */}


             <div className='contact-container'>

                <div className='contact-heading'>

                    <h2 className='contact-title'> Contact Us </h2>
                    <p className='contact-subtitle'> Any question or remarks? Just write us a message!</p>

                </div>

                <div className='contact-section'>

                    <div className='contact-left'>

                        <h2 className='contact-info'>Contact Information</h2>
                        <p className='contact-info-subtitle'>Get In Touch</p>

                        <p className="contact-info-text">
                        <span className="contact-info-span">
                            
                            <img src="/bxs_phone-call.png"  alt=""/> 
                            
                            </span>

                              +1012 3456 789
                         </p>


                        <p className="contact-info-text">
                        <span  className="contact-info-span">  
                        
                        <img  src="/ic_sharp-email.png" alt=""/>  
                        
                        </span>

                        demo@gmail.com
                         </p>

                         <p className="contact-info-text">
                         <span className="contact-info-span">
                            
                             <img src="/carbon_location-filled.png" alt=""/>
                            
                             </span>

                          Sir Howard Douglas Hall P.O. Box 4400. Fredericton, New Brunswick Canada E3B 5A3
                       </p>

                    </div>





                    <div className='contact-right'>

                        <div className='contact-right-row'>

                            <div className='contact-col-1'>
                                <div class="form-outline">
                                  <label class="form-label" for="typeText"> Name </label>
                                  <input type="text" id="typeText" class="form-control" />
                   
                                    </div>
                            </div>



                            <div className='contact-col-2'>
                            <div class="form-outline">

                            <label class="form-label" for="typeEmail">Email</label>
                            <input type="email" id="typeEmail" class="form-control" />

                            </div>
                            </div>

                        </div>


                        <div class="form-outline">
                            <label class="form-label" for="textAreaExample">Message</label>
                            <textarea class="form-control" id="textAreaExample" rows="5"></textarea>
                            
                        </div>


                        <NavLink to={"#"}>
                         <button className="contact-button">Send Message</button>
                        </NavLink>
                        
                        </div>


                </div>




             </div>











            <Footer/>

        </div>


    )







}

export default Contact;
  