import React, { useState } from "react";
import "./ImageSlider.css" 
import { Scrollchor } from 'react-scrollchor'; // Allows hash links (right nav dots)

const sliderStyles = {
  position: "relative",
  height: "100%",
};


const ImageSlider = ({ photos }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePhotoClick = (photo, index) => {
    console.log("/assets/"+photo.directory);
    setSelectedPhoto(photo);
    setCurrentIndex(index);

      // Scroll to the bottom of the screen
     

  };

  return (
    <div>
    <div className="img-slider" style={sliderStyles}>
    
      <div className="image-gallery">
        <Scrollchor to="#selected-photo" className="scrollchor-con">
        {photos.map((photo, index) => (
          <img
            className="image-slide"
            src={"/assets/"+photo.directory}
            key={index}
            
            onClick={() => handlePhotoClick(photo, index)}
          />
        ))}
        </Scrollchor>
      </div>
    
      </div>
      {selectedPhoto && (
        <div>

          <div className="questions">
            <ol className="o-list">
              <li>
                What does the photograph reveal about the tasks involved in
                peacekeeping during this mission?
              </li>
              <li>
                What can we infer about attitudes toward the mission
                from some native groups in the area?
              </li>
              <li>
                What connections can be made between this photograph and items
                in the timeline?
              </li>
              <li>
                Create inquiry questions that emerge from an examination of the
                photograph. Share and discuss with peers.
              </li>
            </ol>
          </div>
          <h3 className="sel-title">Selected Photo: </h3>
          <div className="img-container" id="selected-photo">
            <img
              className="sel-img"
              src={"/assets/"+selectedPhoto.directory}
              alt="Selected Photo"
            />
          </div>

          
        </div>
      )}
    
    </div>
  );
};

export default ImageSlider;
