
import React from "react";

import './App.css';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ScrollToTop from "./components/Navigation/ScrollToTop"
import MapMissionControlScrollama from "./components/MissionControl/MapMissionControlScrollama";
import StudentTeacherActivities from "./components/StudentTeacherActivities/StudentTeacherActivities";
import LandingPage from "./components/LandingPage/LandingPage";
import Documents from "./components/Documents/Documents";
import Map3D from "./components/Timeline/Map3DScrollama";
import CanadaRole from "./components/Canada'sRole/Canada'sRole";
import Map3DScrollama from "./components/Timeline/Map3DScrollama";
import OneDayOption from "./components/StudentTeacherActivities/OneDayOption";
import ThreeDayOption from "./components/StudentTeacherActivities/ThreeDayOption"
import FiveDayOption from "./components/StudentTeacherActivities/FiveDayOption"
import MainLandingPage from "./components/MainLandingPage/MainLandingPage"
import About from "./components/MainLandingPage/About"
import VeteranInterviews from "./components/MainLandingPage/VeteranInterviews"
import TeacherResources from "./components/MainLandingPage/TeacherResources"
import Contact from "./components/MainLandingPage/Contact"
import Team from "./components/MainLandingPage/Team"



function App() {

     

	return (
        <Router>
            <ScrollToTop>
     <div className="App">
         
     <Routes>
        <Route exact path="/mission-control" element={<MapMissionControlScrollama />}/>
        <Route exact path="/student-teacher-activities" element={<StudentTeacherActivities />} />
        <Route exact path="/missions" element={<LandingPage />} />
		<Route exact path="/documents" element={<Documents />} />
		<Route exact path="/timeline" element={<Map3DScrollama />} />
		<Route exact path="/canada's-role" element={<CanadaRole />} />
        <Route exact path="/oneday-option" element={<OneDayOption />} />
        <Route exact path="/threeday-option" element={<ThreeDayOption />} />
        <Route exact path="/fiveday-option" element={<FiveDayOption />} />
        <Route exact path="/" element={<MainLandingPage/>} />
        <Route exact path="/about-us" element={<About/>} />
        <Route exact path="/veteran-interviews" element={<VeteranInterviews/>} />
        <Route exact path="/teacher-resources" element={<TeacherResources/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="/team" element={<Team/>} />
     </Routes>
     

     
     {/* <TopNav /> */}


			
    </div>
    </ScrollToTop>
    </Router>
	);
}

export default App;
