import React, { useEffect, useState } from 'react';
import Introduction from '../MissionControl/Introduction';
import './VideoActivity.css'
import VideoSlider from './VideoSlider';
import { useLocation } from 'react-router-dom';

const VideoActivity = () => {

  // These variables are for the props to be passed between pages
  const location = useLocation();
  const { state } = location;
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);
  const [jsonResult, setJSONResult] = useState({});
  const defaultVideos = [
    {url: 'https://www.youtube.com/embed/N4CDRJ7xzX4?enablejsapi=1&version=3&playerapiid=ytplayer', txt: "Introduction Video"},
    {url: 'https://www.youtube.com/embed/0e7LZu_ceZQ?enablejsapi=1&version=3&playerapiid=ytplayer', txt: "Short Video Backstory"},
    {url: 'https://www.youtube.com/embed/lLvqZ1ufLwk?enablejsapi=1&version=3&playerapiid=ytplayer', txt: "Long Video Backstory"}
    // Add more video URLs as needed
  ];
  const [videos, setVideos] = useState(defaultVideos);
  
  useEffect(()=>{
      window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
      setMissionID(state.mission_id);
      setLanguage(state.language);
      console.log("Video Activity Component - Mission ID: " + missionID + " - Language: " + language);
      getData(missionID);
      
  }, [])

  const getData = (m_id) => {
    fetch('../../assets/'+m_id+'/mission_control.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(myJson => {
      console.log("This is myJson from video activity");
      console.log(myJson);
      setJSONResult(myJson);
      // Format the json into defaultVideo objects
      // const mc_videos = myJson.mission_videos;
      // if (mc_videos){
        
        // The following should work if videos have been added to Mission_Videos table. This allows you to add any number of videos per mission
        // var new_videos_arr = [];
        // mc_videos.forEach(vid => {
        //   var new_videos_obj = {url: "", txt:""}
        //   if (vid.youtube_bool == 1 && vid.youtube_url.trim() != ""){
        //     // The video is hosted on YouTube
        //     new_videos_obj = {url: 'https://www.youtube.com/embed/'+vid.youtube_url+'?enablejsapi=1&version=3&playerapiid=ytplayer', txt: vid.title};
        //   }
        //   else {
        //     // This is a local video
        //     // *** still need to implement with VideoSlider
        //   }
        //   new_videos_arr.push(new_videos_obj);
        // });
      //   setVideos(new_videos_arr);
      //   console.log("Set Videos: ");
      //   console.log(JSON.stringify(setVideos));
      // }
      const mission_info = myJson.mission_information;
      if (mission_info){
        const new_videos_arr = [
            {url: 'https://www.youtube.com/embed/'+mission_info.introduction_video+'?enablejsapi=1&version=3&playerapiid=ytplayer', txt: "Introduction Video"},
            {url: 'https://www.youtube.com/embed/'+mission_info.backstory_video_short+'?enablejsapi=1&version=3&playerapiid=ytplayer', txt: "Short Video Backstory"},
            {url: 'https://www.youtube.com/embed/'+mission_info.backstory_video_long+'?enablejsapi=1&version=3&playerapiid=ytplayer', txt: "Long Video Backstory"}
          ];

        setVideos(new_videos_arr);
        console.log("Set Videos: ");
        console.log(JSON.stringify(new_videos_arr));
      }
      else{
        console.log("No videos found in VideoActivity.js");
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  

  return (
    <div className = "video-activity-container">

      <div className='video-slider-container'>
        <VideoSlider videos={videos} />
      </div>
  
    </div>
  );
};


export default VideoActivity;