import React, { useEffect, useState } from 'react';
import './Backstory.css'
import Map from './Map'
import { useLocation } from 'react-router-dom';
import HtmlReactParser from 'html-react-parser';

    
function ConflictCause(){
    // These variables are for the props to be passed between pages
    const location = useLocation();
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(state.language);
    const [causeData,setData] = useState({});

    const getData=(m_id)=>{
        fetch('../assets/'+m_id+'/mission_control.json', {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        )
        .then(function(response){
          //console.log(response)
          return response.json();
        })
        .then(function(myJson) {
          console.log(myJson);
          setData(myJson)
    
        });
    }
    
    useEffect(()=>{
        getData(missionID);
    },[])

    return(
       
        <div>
        {/* <Map  /> */}

        <div  className='mission-control-map '>
            <div className='card'>
                <h2 className='heading-card-mc'> CAUSE OF CONFLICT</h2>
                <p className='text-card'>
                {
                    causeData.mission_information && HtmlReactParser(causeData.mission_information.cause_of_conflict)
                }

                </p>
            </div>
        </div>
    </div>
)
}

export default ConflictCause;