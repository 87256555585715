import React, { useRef, useEffect, useState } from 'react';
import injectSheet from 'react-jss';
import { Scrollama, Step } from 'react-scrollama';
import Introduction from '../MissionControl/Introduction'
import Backstory from '../MissionControl/Backstory'
import ConflictCause from '../MissionControl/ConflictCause'
import Setting from '../MissionControl/Setting'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import TopNav from '../Navigation/TopNav';
import '../Navigation/Sidebar.css'
import './Map.css'
import './MapMissionControl.css'
import { slide as Menu } from 'react-burger-menu'
import { Link, useLocation } from 'react-router-dom';

mapboxgl.accessToken = 'pk.eyJ1IjoibGZjaG9ja2V5IiwiYSI6ImNqdHQ3OTJzYTAzbDM0NG9hcDJhcHM3YXgifQ.-xAMcIMztR--wvn0P57RnQ';

const styles = {
  navbar: {
    position: 'fixed',
    display: 'flex',
    top: 0,
    right: 0,
    zIndex: 1,
    '& a': {
      display: 'block',
      fontSize: '20px',
      padding: '20px',
    },
  },
  pageTitle: {
    textAlign: 'center',
    fontSize: 22,
    margin: '90px 0 10px',
    visibility: 'hidden',
  },
  description: {
    maxWidth: 600,
    margin: '10px auto 30px',
    fontSize: 22,
    lineHeight: '28px',
    '& a': {
      color: 'black',
    },
  },
  pageSubtitle: {
    textAlign: 'center',
    fontSize: 22,
    color: '#888',
  },
  graphicContainer: {
    padding: '40vh 2vw 20vh',
    display: 'flex',
    justifyContent: 'space-between',
  },
  graphic: {
    flexBasis: '60%',
    position: 'sticky',
    width: '100%',
    height: '60vh',
    top: '20vh',
    backgroundColor: '#aaa',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontSize: '5rem',
      fontWeight: 700,
      textAlign: 'center',
      color: '#fff',
    },
  },
  scroller: {
    flexBasis: '35%',
  },
  steps: {
    margin: '0 auto 3rem auto',
    padding: '180px 0',
    border: '1px solid #333',
    '& p': {
      textAlign: 'center',
      padding: '1rem',
      fontSize: '1.8rem',
      margin: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  button: {
    backgroundColor: '#3773ac',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '6px',
    textAlign: 'center',
    display: 'block',
    maxWidth: 220,
    margin: '10px auto 30px',
    fontSize: 19,
    lineHeight: '28px',
    textDecoration: 'none',
  },
  subhed: {
    maxWidth: 600,
    margin: '10px auto 15px',
    fontSize: 22,
    lineHeight: '28px',
    '& a': {
      color: 'black',
    },
    textAlign: 'center',
  },
  whoUsing: {
    maxWidth: 960,
    margin: '30px auto 100px',
    fontSize: 19,
    lineHeight: '26px',
    gridAutoRows: 'minmax(100px, auto)',
    '& a': {
      color: 'black',
    },
    '& img': {
      width: '100%',
    },
    display: 'grid',
    gridTemplateColumns: '2fr 5fr',
    '& > div': {
      padding: '16px 0',
      borderTop: '1px solid #ccc',
      '&:nth-child(odd)': {
        paddingRight: '13px',
        borderRight: '1px solid #ccc',
      },
      '&:nth-child(even)': {
        paddingLeft: '13px',
      },
    },
  },
};

const MapMissionControlScrollama = () => {
  // These variables are for the props to be passed between pages
  const location = useLocation();
  const { state } = location;
  console.log ("MMCS useLocation: " + JSON.stringify(location));
  const [missionID, setMissionID] = useState(state.mission_id);
  const [language, setLanguage] = useState(state.language);
  
  useEffect(()=>{
    console.log("MMCS Mission ID: " + state.mission_id);
		setMissionID(state.mission_id);
    setLanguage(state.language);
    console.log("MMCS Mission ID: " + missionID + " - Language: " + language);
	}, [])
  
  // Refs
  const introductionRef = useRef();
  const backstoryRef = useRef();
  const conflictRef = useRef();
  const settingRef = useRef();
  const mapContainer = useRef();
  const map = useRef();
  const steps = ["summary", "cause", "setting"];
  const [result, setResult] = useState({});
  const [currentStep, setCurrentStep] = useState(2);
  const [mapLayersLoaded, setMapLayersBool] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    console.log("useEffect started");
    getData(missionID);
    if (map.current) return; // initialize map only once
    console.log("map is not loaded");
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/lfchockey/clid5nak2002p01p6hd1f9kb4', //'mapbox://styles/mapbox/satellite-streets-v12',
      center: [40, -30],
      zoom: 1.5,
      projection: 'mercator',
      scrollZoom: false
    });

    // When the map loads, add the sources and layers to use for polygons, country colours, arrows, etc.
    map.current.on('load', () => {
    
        const layers = map.current.getStyle().layers;
        // Find the index of the first symbol layer in the map style.
        //    The highlighted-countries layer will be placed below this one in the map layer stack
        let firstSymbolId;
        for (const layer of layers) {
            //console.log(layer.type + " - " + layer.id);
            if (layer.type === 'symbol') {
            firstSymbolId = layer.id;
            break;
            }
        }
        
        // Add source for country polygons using the Mapbox Countries tileset
        // The polygons contain an ISO 3166 alpha-3 code which can be used to for joining the data
        // https://docs.mapbox.com/vector-tiles/reference/mapbox-countries-v1
        map.current.addSource('countries', {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1'
        });

        function createCountryLayer(id, color) {
            return {
                'id': id,
                'type': 'fill',
                'source': 'countries',
                'source-layer': 'country_boundaries',
                'paint': {
                  'fill-outline-color': '#484896',
                  'fill-color': color,
                  'fill-opacity': 0.9
                },
                // Display none by adding a filter with an empty string.
                'filter': ['in', 'iso_3166_1', '']
            };
        }
            
        map.current.addLayer(createCountryLayer('countries-highlighted1', '#44bef1'), firstSymbolId);
        map.current.addLayer(createCountryLayer('countries-highlighted2', '#b18f06'), firstSymbolId);
        map.current.addLayer(createCountryLayer('countries-highlighted3', '#0623b1'), firstSymbolId);
        map.current.addLayer(createCountryLayer('countries-highlighted4', '#eb0f0f'), firstSymbolId);
        map.current.addLayer(createCountryLayer('countries-highlighted5', '#1c8112'), firstSymbolId);
        
        map.current.addSource('polygonSource', {
          'type': 'geojson',
          'data': {
              'type': 'Feature',
              'geometry': {
                'type': 'Polygon',
                'coordinates': [[]]
              }
          }
        });
        
        map.current.addLayer({
          'id': 'polygonLayer',
          'type': 'fill',
          'source': 'polygonSource',
          'layout': {},
          'paint': {
              'fill-color': '#0080ff',
              'fill-opacity': 0.4
          }
        });
        setMapLayersBool(true);

        // *** Initiate the first map animation
        //console.log("Map layers now loaded, call onStepEnter with 'summary' hard-coded.")
        //onStepEnter('summary');
        
    });    
  }, []);

  const getData = (m_id) => {
    fetch('../assets/'+m_id+'/mission_control.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(myJson => {
        setResult(myJson);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  


  const isObjectEmpty = objectName => {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  };

  const flyToLocation = chapterName => {
    console.log("flyToLocation chapterName: " + chapterName);
    console.log(result);
    if(typeof result !== "undefined" && mapLayersLoaded) {
      let longitude, latitude, zoom, pitch, bearing;
      if (chapterName === 'summary') {
        const intent = result.mission_locations.intent;
        const countries_json = cleanJSONCountries(intent.countries_json);
        //console.log(countries_json);
        changeCountryColours(countries_json);
        latitude = intent.latitude;
        longitude = intent.longitude;
        zoom = intent.zoom;
        pitch = intent.pitch;
        bearing = intent.bearing;
      } else if (chapterName === 'cause') {
        const conflict = result.mission_locations.cause; 
        const countries_json = cleanJSONCountries(conflict.countries_json);
        changeCountryColours(countries_json);
        latitude = conflict.latitude;
        longitude = conflict.longitude;
        zoom = conflict.zoom;
        pitch = conflict.pitch;
        bearing = conflict.bearing;
      } else if (chapterName === 'setting') {
        const setting = result.mission_locations.setting;
        const countries_json = cleanJSONCountries(setting.countries_json);
        changeCountryColours(countries_json);
        latitude = setting.latitude;
        longitude = setting.longitude;
        zoom = setting.zoom;
        pitch = setting.pitch;
        bearing = setting.bearing;
      }
      const animationOptions = {
        center: [longitude, latitude],
        duration: 4000,
        easing: easingFunctions['linear'],
        offset: [0, 0],
        bearing: bearing,
        zoom: zoom,
        pitch: pitch,
        animate: true,
        essential: true
        
      };
    
      map.current.flyTo(animationOptions);
    }
  };

  // declare various easing functions. easing functions mathematically describe how fast a value changes during an animation.
  	// 		each function takes a parameter t that represents the progress of the animation. 
	//		t is in a range of 0 to 1 where 0 is the initial state and 1 is the completed state.
	var easingFunctions = {
		linear: function(t) {
				return t;
		},
		quad: function(t) {
				return t*t;
		},
		// start slow and gradually increase speed
		log: function (t) {
				return 1.1*Math.log2(t)+0.2;
		},
		// start slow and gradually increase speed
		easeInCubic: function (t) {
				return t * t * t;
		},
		// start fast with a long, slow wind-down
		easeOutQuint: function (t) {
				return 1 - Math.pow(1 - t, 5);
		},
		// slow start and finish with fast middle
		easeInOutCirc: function (t) {
				return t < 0.5
				? (1 - Math.sqrt(1 - Math.pow(2 * t, 2))) / 2
				: (Math.sqrt(1 - Math.pow(-2 * t + 2, 2)) + 1) / 2;
		},
		// fast start with a "bounce" at the end
		easeOutBounce: function (t) {
			var n1 = 7.5625;
			var d1 = 2.75;
			
			if (t < 1 / d1) {
				return n1 * t * t;
			} else if (t < 2 / d1) {
				return n1 * (t -= 1.5 / d1) * t + 0.75;
			} else if (t < 2.5 / d1) {
				return n1 * (t -= 2.25 / d1) * t + 0.9375;
			} else {
				return n1 * (t -= 2.625 / d1) * t + 0.984375;
			}
		}
	};

	function clearMapCountryColours(){
    if (!map.current) return; // The back button may trigger clearing the map before it loads the layers
    if (mapLayersLoaded){
      map.current.setFilter('countries-highlighted1', [
        'in',
        'iso_3166_1',
        ''
      ]);
      map.current.setFilter('countries-highlighted2', [
        'in',
        'iso_3166_1',
        ''
      ]);
      map.current.setFilter('countries-highlighted3', [
        'in',
        'iso_3166_1',
        ''
      ]);
      map.current.setFilter('countries-highlighted4', [
        'in',
        'iso_3166_1',
        ''
      ]);
      map.current.setFilter('countries-highlighted5', [
        'in',
        'iso_3166_1',
        ''
      ]);
    }
	}
	
	// Accepts an array of iso2 (countries_arr) and the colour as a string id 
	function changeActiveCountryColour(countries_arr, colour) {
		console.log("inside changeActiveCountryColour country: " + countries_arr);
	
		// Create the options for the filter
		var options = ['in', 'iso_3166_1']; 
	
		if (countries_arr.length > 0){
			// Push the names of the countries to be filtered onto the end of the array
			countries_arr.forEach(c => {
				options.push(c);
			}); 
		}
	
	
		// This filters the appropriate alliances based on the colour found
		if (colour === "UNBlue"){
			map.current.setFilter('countries-highlighted1', options);
		}
		else if (colour === "mainGold"){
			map.current.setFilter('countries-highlighted2', options);
		}
		else if (colour === "positiveBlue") {
			map.current.setFilter('countries-highlighted3', options);
		}
		else if (colour === "negativeRed"){
			map.current.setFilter('countries-highlighted4', options);
		}
		else if (colour === "otherGreen"){
			map.current.setFilter('countries-highlighted5', options);
		}
		else
		{
			map.current.setFilter('countries-highlighted1', options); 
		}
	}
	
	
	// Accepts a JSON object of colours and arrays of iso2 country ids 
	function changeCountryColours(countries_arr) {

        //console.log(result);
        if (isObjectEmpty(result)){
            console.log("Result object is empty. Return from changeCountryColours");
            return;
        }
		//countries_arr = cleanJSONCountries(countries_arr);
		
        // if (isJsonString(countries_arr)){
		// 	console.log("JSON is valid");
		// 	console.log(JSON.stringify(countries_arr));
		// }
		// else {
		// 	console.log("There is something wrong with the countries_arr parsed as JSON");
		// }
		console.log("changeCountryColours() country: " + JSON.stringify(countries_arr));
    console.log("Map layers loaded in change country colours: " + mapLayersLoaded);
		//countries_arr = JSON.parse(JSON.stringify(countries_arr));
    if(mapLayersLoaded){
        const colorOptions = {
            red: { filter: 'countries-highlighted4', options: ['in', 'iso_3166_1'] },
            blue: { filter: 'countries-highlighted3', options: ['in', 'iso_3166_1'] },
            gold: { filter: 'countries-highlighted2', options: ['in', 'iso_3166_1'] },
            green: { filter: 'countries-highlighted5', options: ['in', 'iso_3166_1'] },
            un: { filter: 'countries-highlighted1', options: ['in', 'iso_3166_1'] }
        };
        
        for (const color of Object.keys(colorOptions)) {
            //const colorData = JSON.parse(countries_arr[color]);
            // colorData should be an array of country objects
            const colorData = countries_arr[color];
            console.log(color + " - " + colorData);
            if(typeof colorData !== "undefined") {
                if (colorData.length > 0) {
                    console.log(`${color} ${JSON.stringify(colorData)}`);
                    const options = colorOptions[color].options;
                    colorData.forEach(country => {
                        if (country.iso2.includes(",")) {
                          const countries_arr = country.iso2.split(',');
                          countries_arr.forEach(c => {
                              options.push(c);
                          });
                        }
                        options.push(country.iso2);
                    });
                    map.current.setFilter(colorOptions[color].filter, options);
                }
            }
        }
    }
	}
	
	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
	
	// Some of the JSON read from the timeline.json needs cleaning before it's usable
	function cleanJSONCountries(json_str){

		json_str = JSON.stringify(json_str);
		json_str = json_str.substring(1, json_str.length-1);
		json_str = json_str.replace(/\\/g, "");
		json_str = JSON.parse(json_str)

		return json_str;
	}
	
	//END OF MAP FUNCTIONALITY IMPLEMENTATION


  // BEGINING OF SIDEBAR FUNCTIONALITY IMPLEMENTAION

  const introduction = useRef(null);
  const backstory = useRef(null);
  const conflict = useRef(null);
  const setting = useRef(null);

  
  const scrollToSection = (elementRef, section) => {
      console.log("scroll to section: " + section);

      window.scrollTo({
          top: elementRef.current.offsetTop,
          behavior: 'smooth'
          
      });
      

      // Check if data object is null or empty before using contents
      const isObjectEmpty = (objectName) => {
          return (
            objectName &&
            Object.keys(objectName).length === 0 &&
            objectName.constructor === Object
          );
      };

      console.log(result);
      if (!isObjectEmpty(result)){
          clearMapCountryColours();
          if (section === "introduction"){

          }
          else if (section === "summary"){
              // const intent = result.mission_locations.intent;
              // var countries_json = cleanJSONCountries(intent.countries_json);
              // changeCountryColours(countries_json);
              flyToLocation(section); //result.mission_locations.intent.latitude, result.mission_locations.intent.longitude, result.mission_locations.intent.zoom);
          }
          else if (section === "cause"){
              // const cause = result.mission_locations.cause;
              // var countries_json = cleanJSONCountries(cause.countries_json);
              // changeCountryColours(countries_json);
              flyToLocation(section); //(cause.latitude, cause.longitude, cause.zoom);
          }
          else if (section === "setting"){
              // const setting = result.mission_locations.setting;
              // var countries_json = cleanJSONCountries(setting.countries_json);
              // changeCountryColours(countries_json);
              flyToLocation(section); //(setting.latitude, setting.longitude, setting.zoom);
          }
      }
      else {
          console.log ("data/result returned empty")
      }

  };

  const onStepEnter = ({ data }) => {
      if(result){
          console.log("onStepEnter: " + data);
          if (data !== steps[currentStep]) {
              setCurrentStep(steps.indexOf(data));
              clearMapCountryColours();
              flyToLocation(data);
          }
          
      }
  };

  return (
    <div>
      <TopNav />
 
      <div className=''>
        <Menu>
          <nav className='mc-sidebar'>

          <Link to="/" style={{ textDecoration: 'none' }}>
                           
            <i class=" fa-solid fa-house"></i> 

                
          </Link>
          
            <div>
              <p className='comp-name'>MISSION CONTROL</p>
              <hr className='hrline' />
            </div>
            <ul style= {{ listStyleType: 'none' }}>
              <li
                onClick={() => scrollToSection(introductionRef, 'introduction')}
                className='side-link menu-item'
              >
                <i class='fa-solid fa-gear'></i> Introduction
              </li>
              <li
                onClick={() => scrollToSection(backstoryRef, 'summary')}
                className='side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> Backstory
              </li>
              <li
                onClick={() => scrollToSection(conflictRef, 'cause')}
                className='side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> Conflict
              </li>
              <li
                onClick={() => scrollToSection(settingRef, 'setting')}
                className='side-link menu-item'
              >
                <i class='fa-solid fa-gear'></i> Setting
              </li>
            </ul>
          </nav>
        {/* </div> */}
    </Menu>
        <div className='' id="page-wrap">
          <div ref={mapContainer} className='map-container'></div>
          <div className='content-section' ref={introductionRef}>
            <Introduction />
          </div>
          <Scrollama
            onStepEnter={onStepEnter}
            //onStepExit={onStepExit}
            progress
            //onStepProgress={onStepProgress}
            offset='250px'
            debug = {false}
          >
            <Step data='summary' key='summary'>
              <div>
                <div ref={ backstoryRef }>
                  <Backstory />
                </div>
              </div>
            </Step>
            <Step data='cause' key='cause'>
              <div>
                <div ref={conflictRef}>
                  <ConflictCause />
                </div>
              </div>
            </Step>
            <Step data='setting' key='setting'>
              <div>
                <div ref={settingRef}>
                  <Setting />
                </div>
              </div>
            </Step>
          </Scrollama>
        </div>
      </div>
    </div>

  );
};

export default injectSheet(styles)(MapMissionControlScrollama);
