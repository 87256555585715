import React from 'react';
import LandingSidebar from '../Navigation/LandingSidebar'
import LandingNav from '../Navigation/LandingNav';
function Documents()   {
	
return (
	<div>
	<LandingNav />
  
	<LandingSidebar />
	</div>
)
};

export default Documents;
