import React from 'react';
import { useCollapse } from 'react-collapsed';

function VeteranInterviews({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
   
    <div>
    <div className='activity-sec'>
      <h2  className='activity-title'>
            Veteran Interviews 
      </h2>


              <div className='activity-content'>

              <p className='mission-text'> Interview a veteran of the mission.  </p>
              

              <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`} {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
    <h2>{title}</h2>

    <div {...getCollapseProps()}>{isExpanded && children}
                 
              
             

              <h3 className='outline-head'>Objective</h3>
              <p className='outline-text'> With your classmates, create a set of interview questions that you feel would be important 
              to ask a veteran that served as a part of Canada’s contingent. </p>

              <p className='outline-big6'>
                Keep in mind the following <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>Historical Thinking Concepts</a> while developing the questions for the interview:<br />
                Primary Source Evidence.
              </p>  

              <h3 className='outline-head'>Action Steps </h3>
              <p className='outline-text'> Determine questions for the interview but please keep in mind the following information:<br /><br />
                What questions may not be appropriate to ask a veteran?

                As a class, decide if you will be interviewing a veteran in-person, over a video conference (Skype, Zoom, Teams, Google Meet, etc.) or only creating a set of questions if a veteran cannot be located. <br /> 
                Brainstorm an essential list of questions you feel would be necessary to ask a veteran to help understand the personal experience while serving in the mission. <br />
                If possible, have your teacher contact the Gregg Centre (University of New Brunswick) to arrange a veteran to participate in a live interview.<br />
                Conduct an interview - arrange for appropriate technology to be able to record the interview.<br /><br />

                Inquiry questions often begin with an open-ended word such as ‘why’, ‘how’, or ‘to what extent’ to anchor your question.  <br />
                By starting your inquiry question in this way the response requires explanation, qualifiers, and depth to properly answer.  <br />
                Using close-ended words such as ‘did’, ‘does’, or ‘are’ only require a yes or no response.  <br />
                To enhance your inquiry question students can embed a concept of historical thinking.  <br />
                For example, what were the major causes of the Suez Crisis? (Many possible depth-required answers)(Cause and Consequence) What was/were the consequence(s) (result) of the Suez Crisis? 

              </p>

              <h3 className='outline-head'>Follow-Up Questions</h3>
              <p className='outline-text'> When interviewing a single veteran, whose voice may be missing that could add extra perspective? </p>


              <h3 className='outline-head'>Next Steps</h3>
              <p className='outline-text'> As a class, analyze and rank the list of questions that were developed by you and your classmates.<br />
                If you interviewed a veteran, discuss what answers you found most impactful.<br />
              </p>
              </div>
              </div>
    </div>
  </div>

  );
}

export default VeteranInterviews;