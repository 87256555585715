import React from 'react'
import TopNav from '../Navigation/TopNav.js';
import {useRef, useEffect, useState} from 'react'
import '../Navigation/LandingSidebar.css'
import './StudentTeacherActivities.css'
import { NavLink, useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'



function StudentTeacherActivities()   {
	
	// These variables are for the props to be passed between pages
    const location = useLocation();
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(state.language);

	const photoActivity = useRef(null);
	
	useEffect(()=>{
        setMissionID(state.mission_id);
        setLanguage(state.language);
        console.log("Student Teacher Activities - Mission ID: " + missionID + " - Language: " + language);
       
    }, [])
		
		const scrollToSection = (elementRef) => {
		   
			window.scrollTo({
				top: elementRef.current.offsetTop,
				behavior: 'smooth'
				
			});
			
	
		};
			return(
				<div>
				<TopNav />
			<div className=''>
		 {/* <div className='flexbox-item flexbox-item-1'>
		 <nav className='stop-nav-items'> 
				 <div>
				<p className="comp-name">  ACTIVITIES</p>
				<hr className='hrline' />
				</div>
				 
				 
				 <ul>
				 <Link className='link-line' to="/oneday-option">
					<li 
						className="land-side-link"> 
						<i class="fa-solid fa-gear"></i> 
						One-Day Option
					 </li>
					 </Link>
					 <Link className='link-line' to="/threeday-option">
					 <li 
						className="land-side-link"> 
						<i class="fa-solid fa-gear"></i> 
						Three-Day Option
					 </li>
					 </Link>
					 <Link className='link-line' to="/fiveday-option">
					 <li 
						className="land-side-link"> 
						<i class="fa-solid fa-gear"></i> 
						Five-Day Option
					 </li>
					 </Link>
				</ul>
				 
				 
					
				
				 
				</nav>
					</div>
	 */}
	         <Menu>
          <nav className='stop-nav-items '>

		  <NavLink to="/" style={{ textDecoration: 'none' }}>
                           
						   <i class=" fa-solid fa-house"></i> 
				 
								 
							 </NavLink>
            <div>
              <p className='comp-name'>ACTIVITIES</p>
              <hr className='hrline' />
            </div>
            <ul style= {{ listStyleType: 'none' }}>
			<NavLink className='link-line' to="/oneday-option" state={state}>
              <li
                className='land-side-link menu-item'
              >
                <i class='fa-solid fa-gear'></i> One-Day Option
              </li>
			  </NavLink>
			  <NavLink className='link-line' to="/threeday-option" state={state}>
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> Three-Day Option
              </li>
			  </NavLink>
			  <NavLink className='link-line' to="/fiveday-option" state={state}>
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> Five-Day Option
              </li>
			  </NavLink>
            </ul>
          </nav>
    </Menu>
				<div className=''>
				
				<div className='content-section-sa'>
				
				<div className='intro-sa'>
    <h2 className='ac-title-text-1'>Select Activity Option</h2>
    <p className='ac-p-text'>Select one of the following options to view appropriately timed activities related to Canada's service and customize your learning experience:</p>
  

</div>

				

				<div className="option-card-container">
      <div className="option-card">
        <h3 className="option-texthead">One-Day Option</h3>
        <p className="option-text">The following activities are designed to introduce you to the mission and Canada's involvement.</p>
		<NavLink to="/oneday-option" state={state}>
                    <button className='option-btn'> View Option
                    </button>
                    </NavLink>
      </div>
      <div className="option-card">
        <h3 className="option-texthead">Three-Day Option</h3>
        <p className="option-text">You can use the following activities to build a greater understanding of Canada's role.</p>
		
		<div >
		<NavLink to="/threeday-option" state={state}>
                    <button className='option-btn'> View Option
                    </button>
                    </NavLink>
					</div>
      </div>
      <div className="option-card">
        <h2 className="option-texthead">Five-Day Option</h2>
        <p  className="option-text">To build a deeper comprehension of the mission that Canada served in, you can work through the following activities.</p>
		<NavLink to="/fiveday-option" state={state}>
                    <button className='option-btn'> View Option
                    </button>
                    </NavLink>
      </div>
    </div>
	</div>
				
			   
		</div>
		</div>	
		</div>		
				
			)
		}
	
	
	
	export default StudentTeacherActivities
