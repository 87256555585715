import React, { useEffect, useState } from 'react';
import "./Introduction.css"
import VideoSection from './VideoSection';
import VideoActivity from '../StudentTeacherActivities/VideoActivity';
import { useLocation } from 'react-router-dom';

function Introduction(){
    // These variables are for the props to be passed between pages
    const location = useLocation();
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(state.language);
    useEffect(()=>{
        setMissionID(state.mission_id);
        setLanguage(state.language);
        console.log("Introduction Component - Mission ID: " + missionID + " - Language: " + language);
    }, [])
    
    return(
    <div className='intro'>
    <h2 className='title-text-1'>Get Started</h2>
    <div className='p-text-con'>

    
    <p className='p-text'>Welcome to the Mission Control. Watch the below videos for an introductory overview</p>
    </div>
    {/* <h2 className='title-text'></h2> */}

    <VideoActivity />
    
</div>
)
}

export default Introduction;