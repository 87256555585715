import React, { useState } from "react";
import "./LandingNav.css";
import { NavLink} from "react-router-dom";
import { slide as Menu } from "react-burger-menu";




function LandingNav() {

  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const toggleResources = () => {
    setIsResourcesOpen(!isResourcesOpen);
    setIsAboutOpen(false);
  };

  const toggleAbout = () => {
    setIsAboutOpen(!isAboutOpen);
    setIsResourcesOpen(false);
  };



  return (
    <div>
    
  <Menu>
          <nav className='stop-nav-items '>

		  <NavLink to="/" style={{ textDecoration: 'none' }}>
                           
						   <i class=" fa-solid fa-house"></i> 
				 
								 
							 </NavLink>
            <div>
              <p className='comp-name'>In Service Of Canada</p>
              <hr className='hrline' />
            </div>
            <ul style= {{ listStyleType: 'none' }}>
			<NavLink className='link-line' to="/" >
              <li
                className='land-side-link menu-item'
              >
                <i class='fa-solid fa-gear'></i> Home
              </li>
			  </NavLink>
			  <NavLink className='link-line' to="/missions">
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> Missions
              </li>
			  </NavLink>
			  <NavLink className='link-line' to="/about-us">
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> About Project
              </li>
			  </NavLink>
        <NavLink className='link-line' to="/team">
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> Our Team
              </li>
			  </NavLink>
        <NavLink className='link-line' to="/teacher-resources">
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> Teacher Resources
              </li>
			  </NavLink>
        <NavLink className='link-line' to="/veteran-interviews">
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> Veteran Interviews
              </li>
			  </NavLink>
        <NavLink className='link-line' to="/contact">
              <li
                className='land-side-link menu-item'
              >
                <i class='menu-item fa-solid fa-gear'></i> Contact Us
              </li>
			  </NavLink>
            </ul>
          </nav>
    </Menu>
    <nav className="main-container">
          <div className="land-logo-section">
        <img loading="lazy" src="/gregg_logo.png" className="logo" />
       
        <NavLink to='/' style={{ textDecoration: 'none' }}>
        <div className="title">In Service Of Canada</div>
            </NavLink>
      </div>

     
     <div
       className="navigation-menu"
    >

      <ul className="menu">

      <li className="land-li "><NavLink to="/" className="land-nav-links" activeClassName="active">Home</NavLink></li>
        <li className="land-li"><NavLink to="/missions" className="land-nav-links" activeClassName="active">Missions</NavLink></li>

        <li className="land-li" onClick={toggleAbout}>
        <span className="land-nav-links">About ▼</span>


        {isAboutOpen && (
            <ul className="resources-dropdown">
              <NavLink to="/about-us" className="dropdown-item" activeClassName="active">
                <li className="dropdown-item-li">
                  About Project
                </li>
              </NavLink>

              <NavLink to="/team" className="dropdown-item" activeClassName="active">
                <li className="dropdown-item-li">
                  Our Team
                </li>
              </NavLink>
          
            </ul>
          )}
          
          
          
          </li>



        <li className="land-li" onClick={toggleResources}>
        <span className="land-nav-links">Resources▼</span>


        {isResourcesOpen && (
            <ul className="resources-dropdown">
              <NavLink to="/teacher-resources" className="dropdown-item" activeClassName="active">
                <li className="dropdown-item-li">
                  Teacher Resources
                </li>
              </NavLink>

              <NavLink to="/veteran-interviews" className="dropdown-item" activeClassName="active">
                <li className="dropdown-item-li">
                  Veteran Interviews
                </li>
              </NavLink>
          
            </ul>
          )}
          
          
          
          </li>
       
       
       
        <li className="land-li"><NavLink to="/contact" className="land-nav-links" activeClassName="active">Contact Us</NavLink></li>


      </ul>

      </div>
    </nav>
    </div>
  );
}

export default LandingNav;