import React from 'react';
import './Team.css';
import LandingNav from '../Navigation/LandingNav';
import Footer from '../Navigation/Footer';
import { NavLink } from 'react-router-dom';
    
function Team(){


    return(

        <div>

            <LandingNav/>

             {/* Team Section */}


             <div className='team-container'>

                <div className='team-heading'>

                    <h2 className='team-title'> Meet The Team </h2>
                    <p className='team-subtitle'> "Members of our team"</p>

                </div>

                <div className='team-section'>




                <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Cindy Brown </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>





            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Lee A Windsor </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>






            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Katelyn Stieva </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>





            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Nancy Day </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>





            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Blake Seward </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>






            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Mason Black </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Delaney Beck </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Timothy Gerald </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Darian Calhoun</h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Taylor Lynn </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Mackenzie Frances Smith </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>







            <div class="team-card">
                <img class="member-img" loading='lazy' src="/assets/images/TEAM-IMAGES/stock1.png" alt="Team Image"></img>
               
                <h4 class="member-name"> Folajimi Oyemomi </h4>
                <p className='member-role'>Director </p>
                <a href="#">
               <img class="linkden-logo" loading='lazy' src="/assets/images/TEAM-IMAGES/linkden-icon.png" alt="Linkden Logo"></img>

               
            </a>
                <p class="member-comment">"Comments by team member. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                ut labore et dolore magna aliqua. Ut enim ad minim veniam."</p>
            </div>




                </div>




             </div>












            <Footer/>

        </div>


    )







}

export default Team;
  