import {useRef} from 'react'
import './LandingSidebar.css'
import PhotoActivity from '../StudentTeacherActivities/PhotoActivity';


function LandingSidebar() {
    
    

    const photoActivity = useRef(null);

    
    const scrollToSection = (elementRef) => {
       
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth'
            
        });
        

    };
   
    
        return(
        <div className='flexbox-container'>
     <div className='flexbox-item flexbox-item-1'>
     <nav className='stop-nav-items'> 
             <div>
            <p className="comp-name">  Menu</p>
            <hr className='hrline' />
            </div>
             
             
             <ul>
                <li onClick = {() => scrollToSection(photoActivity) }
                    className="land-side-link"> 
                    <i class="fa-solid fa-gear"></i> 
                    Photo Activity
                 </li>

            </ul>
             
             
                
            
             
            </nav>
                </div>

            <div className='flexbox-item flexbox-item-2'>
            
            <div className='content-section' ref ={photoActivity}>
            <PhotoActivity   />
            </div>
            
           
    </div>
    </div>	
    
            
        )
    }



export default LandingSidebar