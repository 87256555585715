import React, { useEffect, useState } from 'react';
import './Backstory.css'
import Map from './Map'
import ReactAudioPlayer from 'react-audio-player';
import { useLocation } from 'react-router-dom'; 
import HtmlReactParser from 'html-react-parser';
    
function Backstory(){
    // These variables are for the props to be passed between pages
    const location = useLocation();
    const { state } = location;
    const [missionID, setMissionID] = useState(state.mission_id);
    const [language, setLanguage] = useState(state.language);
    const [backstoryData,setData] = useState({});
    const [isContentVisible, setContentVisibility] = useState(false);

    const getData=(m_id)=>{
        fetch('../assets/'+m_id+'/mission_control.json', {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        )
        .then(function(response){
          return response.json();
        })
        .then(function(myJson) {
          setData(myJson)
        });
    }
    
    useEffect(()=>{
      getData(missionID);
    },[])

    const toggleContent = () => {
      setContentVisibility(!isContentVisible);
    };

  
    

    return (
      <div>
        {/* <Map  /> */}
        

        <div  className='mission-control-map '>
     
            <div className='card'>
                <h2 className='heading-card-mc'> MISSION BACKSTORY</h2>


                <div className="audio-section">
            <i class="fa-solid fa-volume-high audio-icon" onClick={toggleContent}></i>
            {isContentVisible && (
              <span className="opened-span">
                
                {/* <ReactAudioPlayer
                    src="/audio-test.mp3"
                    controls
                    className='audio-player'
              /> */}
              
              <p className='audio-section'>Audio currently unavailable</p>
              </span>
            )}
      
      </div>
            
                <p className='text-card'>
                {
                    backstoryData.mission_information && HtmlReactParser(backstoryData.mission_information.summary)
              
                }
                           

                </p>
            </div>
        </div>
    </div>
      
    )
    
  }

  export default Backstory;


      