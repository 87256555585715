import React from 'react';
import { useCollapse } from 'react-collapsed';
import PhotoActivity from './PhotoActivity';
import { Link } from 'react-router-dom';

function InterrogatingPhoto({ title, children }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    
    <div className='activity-sec'>
				<h2  className='activity-title'>
                Deconstructing a Photo
				</h2>
        <div className='activity-content'>
            <p className='mission-text'>
              Learn how to deconstruct specific photographs in order to better understand a historical event.
            </p>

                 <button className={`expand-btn ${isExpanded ? 'expand-btn-expanded' : 'expand-btn-collapsed'}`}  {...getToggleProps()}>{isExpanded ? 'Collapse Activity' : 'Expand Activity'}</button>
                 <h2>{title}</h2>
           

              <div {...getCollapseProps()}>{isExpanded && children}
            

                  {/* <h3 className='outline-head'>Mission</h3>
                  <p className='outline-text'> Watch a video that outlines the conflict and the events that led 
                  to the need for international involvement.   </p> */}
                  

                  <h3 className='outline-head'>Objective</h3>
                  <p className='outline-text'> A picture is worth a thousand words. <br />
                    Deconstruct what you see in the photograph by answering the - Who, What, Where, When, Why and How - to the best of your ability.
                </p>

                  <p className='outline-big6'>
                      Keep in mind the following <a href='https://historicalthinking.ca/historical-thinking-concepts' target='_blank'>Historical Thinking Concepts</a> when watching the video: <br />
                      Historical Perspectives, Ethical Dimension, Primary Source images and Historical Significance.
                  </p>

                  <h3 className='outline-head'>Action Steps </h3>
                  <p className='outline-text'> 
                    Find the list of photographs at the bottom of this activity. <br />
                    Select one photograph that best illustrates this conflict and explain the thinking behind this selection <br />
                    OR illustrate/describe a photograph that could have been taken to enhance the historical understanding of the event.

                  </p>

                  <h3 className='outline-head'>Follow-Up Questions</h3>
                  <p className='outline-text'> 
                    If you were a newspaper editor, what title would you give the photograph? Can you make up the first paragraph of an article?<br />
                    In what ways is this photograph important to understanding the event?<br />
                    How does this photograph help to tell the larger story connected to the event?<br />

                  </p>


                  <h3 className='outline-head'>Debrief & Next Steps</h3>
                  <p className='outline-text'> 
                    Class Discussion: Identify the different themes that arise from the photos.<br />
                    What does this reveal about the complexities of the mission?

                     </p>
                     <PhotoActivity   />
                </div>
             
              

        </div>

		</div>
			

  );
}

export default InterrogatingPhoto;